/* 
THIS CSS IS A COMPLETE REHAUL OF THE ORIGINAL 
COMPONENT IN ORDER TO WORK IN REACT AND RESPONSIVELY.
*/

#carousel {
  perspective: 400px;
  -moz-perspective: 400px;
  -ms-perspective: 400px;
  padding-top: 4rem;
  font-size: 0;
  margin-bottom: 0rem;
  overflow: hidden;
}

figure#spinner {
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  height: 300px;
  transform-origin: 50% 50% -500px;
  -moz-transform-origin: 50% 50% -500px;
  -ms-transform-origin: 50% 50% -500px;
  transition: 1s;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    #carousel {
      -webkit-perspective: 1200px;
      perspective: 1200px;
    }

    figure#spinner {
      -webkit-transform-origin: center;
      transform-origin: center;
    }
  }
}

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    figure#spinner {
      -webkit-transform-origin: center;
      transform-origin: center;
    }
  }
}

/* Safari 6.1-10.0 (not 10.1) */
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  figure#spinner {
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}

figure#spinner img {
  width: 75%;
  max-width: 525px;
  position: absolute;
  left: 10%;
  -webkit-transform-origin: 50% 50% -500px;
  -moz-transform-origin: 50% 50% -500px;
  -ms-transform-origin: 50% 50% -500px;
  transform-origin: 50% 50% -500px;
  outline: 1px solid transparent;
  margin: 0;
}

figure#spinner img:nth-child(1) {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
}
figure#spinner img:nth-child(2) {
  transform: rotateY(-72deg);
  -webkit-transform: rotateY(-72deg);
}
figure#spinner img:nth-child(3) {
  transform: rotateY(-144deg);
  -webkit-transform: rotateY(-144deg);
}
figure#spinner img:nth-child(4) {
  transform: rotateY(-216deg);
  -webkit-transform: rotateY(-216deg);
}
figure#spinner img:nth-child(5) {
  transform: rotateY(-288deg);
  -webkit-transform: rotateY(-288deg);
}

div#carousel ~ span {
  color: #2b2b2b;
  margin: 5%;
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  transition: 0.6s color;
  position: relative;
  margin-top: -6rem;
  border-bottom: none;
  line-height: 0;
}

div#carousel ~ span:hover {
  color: #888;
  cursor: pointer;
}

.image {
  border: 1px solid #2b2b2b;
  border-radius: 10px;
}

@media (prefers-color-scheme: dark) {
  div#carousel ~ span {
    color: #eeeeee;
    transition: 0.6s color;
  }

  div#carousel ~ span:hover {
    color: #eeeeee;
    cursor: pointer;
  }

  .image {
    border: 1px solid #eeeeee;
    border-radius: 10px;
  }
}

@media (prefers-color-scheme: light) {
  div#carousel ~ span {
    color: #2b2b2b;
    transition: 0.6s color;
  }

  div#carousel ~ span:hover {
    color: #888;
    cursor: pointer;
  }

  .image {
    border: 1px solid #2b2b2b;
  }
}

@media only screen and (min-width: 400px) {
  figure#spinner {
    height: 350px;
  }
}

@media only screen and (min-width: 500px) {
  figure#spinner {
    height: 400px;
  }
}

@media only screen and (min-width: 600px) {
  #carousel {
    perspective: 600px;
  }

  figure#spinner {
    height: 400px;
  }

  figure#spinner img {
    left: 20%;
    width: 60%;
  }
}

@media only screen and (min-width: 700px) {
  #carousel {
    perspective: 1200px;
  }

  figure#spinner {
    height: 425px;
  }

  figure#spinner img {
    left: 30%;
    width: 50%;
  }
}

@media only screen and (min-width: 1100px) {
  figure#spinner img {
    width: 40%;
  }
}
