nav {
  margin: 20px;
}

a {
  font-family: 'Bungee Inline', cursive;
  color: #2b2b2b;
  background-color: transparent;
  font-size: 16px;
  text-decoration: underline;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 5px;
  
}

a:hover {
  color: white;
  background-color: #000092;
  border: 1px solid white;
  text-decoration: underline;
  transition: 0.4s;
}

@media only screen and (min-width: 1000px) {
  a {
    font-size: 22px;
   }
}

@media (prefers-color-scheme: dark) {
  a {
    color: #eeeeee;
    background-color: transparent;
  }
  
  a:hover {
    color: #eeeeee;
    background-color: #4381C1;
    border: 1px solid #2b2b2b;
    text-shadow: 1px 1px #2b2b2b;
  }
}

@media (prefers-color-scheme: light) {
  a {
    color: #2b2b2b;
    background-color: transparent;
  }
  
  a:hover {
    color: white;
    background-color: #000092;
    border: 1px solid white;
  }
}