.tech {
  width: 55%;
  word-wrap: normal;
  list-style: none;
}


.tech-stack {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 75%;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

@media (prefers-color-scheme: dark) {
  .tech:hover {
    color: yellow;
    transition: 0.3s;
  }
}

@media (prefers-color-scheme: light) {
  .tech:hover {
    color: blue;
    transition: 0.3s;
  }
}

@media only screen and (min-width: 400px) {
  .tech {
    width: 45%;
  }
}

@media only screen and (min-width: 700px) {
  .tech {
    width: 30%;
  }
}

@media only screen and (min-width: 1000px) {
  .tech {
    width: 20%;
  }
}


